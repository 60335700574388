<!--
 * @Author: wxb
 * @Date: 2021-06-22 00:27:37
 * @LastEditTime: 2021-11-05 16:38:15
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Project\builtPro.vue
-->
<template>
  <div class="containner">
    <div class="content">
      <div class="search-info">
        <div class="project-search">
          <div class="project-search-input">
            <el-input class="project-search-field"
                      v-model="searchForm.keyword"
                      placeholder="输入关键词搜索.."
                      clearable
                      @keyup.enter.native="searchProject"></el-input>
            <button type="button"
                    class="project-search-btn"
                    @click="searchProject">搜索</button>
          </div>
        </div>
        <div class="search-senior">
          <button @click="showSenior=!showSenior">筛选 <i class="el-icon-arrow-up"
               v-show="showSenior"></i>
            <i class="el-icon-arrow-down"
               v-show="!showSenior"></i>
          </button>
        </div>
      </div>
      <div class="project-search-senior"
           v-show="showSenior">
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">项目类型：</div>
          <div class="project-search-senior-item-content">
            <div class="notice-type-content"
                 :class="{autoH:autoH.protype}">
              <div class="notice-type-content-item"
                   :class="index===activeNoticeType ? 'active' : ''"
                   v-for="(item,index) in projectType"
                   :key="index"
                   @click="handleClick4NoticeType(index)">{{item.DICTIONARYITEM_ITEMNAME}}</div>
            </div>
            <div class="showmore-tools"
                 @click="autoH.protype = !autoH.protype">
              <span v-show="!autoH.protype"> 更多<i class="el-icon-arrow-down"></i></span>
              <span v-show="autoH.protype"> 收起<i class="el-icon-arrow-up"></i></span>
            </div>
          </div>
        </div>
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">项目金额：</div>
          <div class="project-search-senior-item-content">
            <div class="price-range">
              <div class="price-range-item">
                <el-input v-model="searchForm.minNum"
                          size="small"
                          placeholder="请输入价格（万元）"></el-input>
              </div>
              <div class="price-range-item-delive">-</div>
              <div class="price-range-item">
                <el-input v-model="searchForm.maxNum"
                          size="small"
                          placeholder="请输入价格（万元）"></el-input>
              </div>
              <div class="price-range-item-btn">
                <el-button size="small"
                           type="primary"
                           @click="searchProject">确定</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">项目地区：</div>
          <div class="project-search-senior-item-content">
            <div class="area-content"
                 :class="{autoH:autoH.area}">
              <div class="area-content-item"
                   :class="index===activeArea ? 'active' : ''"
                   v-for="(item,index) in province"
                   :key="index"
                   @click="handleClick4Area(index)">{{item.DICTIONARYITEM_ITEMNAME}}</div>
            </div>
            <div class="showmore-tools"
                 @click="autoH.area = !autoH.area">
              <span v-show="!autoH.area"> 更多<i class="el-icon-arrow-down"></i></span>
              <span v-show="autoH.area"> 收起<i class="el-icon-arrow-up"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="project"
           v-show="!noData && canSee">
        <div class="project-list">
          <div class="project-list-title">项目列表</div>
          <div class="project-list-info">
            <div class="project-list-info-item"
                 @click="goToAnalysis(item.XMY_TREEPROINFO_ID)"
                 v-for="(item,index) in resultLst"
                 :key="index">
              <div class="project-info">
                <div class="project-title">
                  <span v-html="formatTitle(item.TREEPROINFO_TEXT)"></span>
                </div>
                <div class="project-time">{{item.TREEPROINFO_SSQX1_TEXT}}</div>
              </div>
              <div class="project-tag-btn">
                <div class="project-tag"
                     v-show="item.TREEPROINFO_XMLX_NAME">
                  <el-tag size="small"
                          v-for="(it,idx) in item.TREEPROINFO_XMLX_NAME.split(',')"
                          :key="idx">{{it}}</el-tag>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="project-page">
          <el-pagination background
                         layout="prev, slot, next"
                         :total="total"
                         @current-change="handleCurrentChange"
                         :current-page.sync="currentPage">
            <span class="current-page">{{currentPage}}</span>
          </el-pagination>
        </div>
      </div>
      <noData tips="哎呀，未查询到相关项目信息"
              v-show="noData"></noData>
      <viporlogin bgStr="3"
                  :type="viporlogin"
                  v-show="!canSee"></viporlogin>
      <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    </div>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getProjectList } from '@/api/project'
import dialogviporlogin from '@/components/dialogviporlogin'
export default {
  name: 'builtPro',
  components: {
    noData,
    viporlogin,
    dialogviporlogin
  },
  data() {
    return {
      noData: false,
      canSee: true,
      viporlogin: 'login',
      searchForm: {
        keyword: '',
        proivnce: '',
        minNum: '',
        maxNum: '',
        projectType: '',
        start: 0,
        limit: 10
      },
      activeNoticeType: 0,
      minPrice: '',
      maxPrice: '',
      activeArea: 0,
      resultLst: [],
      currentPage: 1,
      total: 0,
      showSenior: true,
      autoH: {
        protype: true,
        area: true
      }
    }
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType',
      province: 'province',
      projectType: 'projectType'
    })
  },
  methods: {
    // 点击项目类型
    handleClick4NoticeType(index) {
      if (!this.canSee) {
        return
      }
      this.activeNoticeType = index
      const projectType = this.projectType[index].DICTIONARYITEM_ITEMCODE
      if (projectType === 'all') {
        this.searchForm.projectType = ''
      } else {
        this.searchForm.projectType = projectType
      }
      // 调用搜索方法
      this.searchProject()
    },
    // 地区点击
    handleClick4Area(index) {
      if (!this.canSee) {
        return
      }
      this.activeArea = index
      const proivnce = this.province[index].DICTIONARYITEM_ITEMNAME
      if (proivnce === '全国') {
        this.searchForm.proivnce = ''
      } else {
        this.searchForm.proivnce = proivnce
      }
      this.searchProject()
    },
    goToAnalysis(id) {
      const authObj = authenticate('builtPro:click', this.memberType)
      if (authObj.flag) {
        const routeData = this.$router.resolve({
          name: 'builtProInfo',
          params: {
            id
          }
        })
        window.open(routeData.href, '_blank')
      } else {
        this.$refs.dialogviporlogin.handleShow(authObj.msg)
      }
    },
    initPage() {
      this.getBuiltPro()
    },
    getBuiltPro() {
      getProjectList(this.searchForm).then(res => {
        if (res.ok) {
          if (res.cnt > 0) {
            this.resultLst = res.lst
            // 条数限制
            if (res.cnt > 100) {
              this.total = 100
            } else {
              this.total = res.cnt
            }
            this.noData = false
          } else {
            this.noData = true
          }
        } else {
          this.$message.error('获取信息异常，请稍后再试')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 搜索
    searchProject() {
      if (!this.canSee) {
        return
      }
      this.searchForm.start = 0
      this.currentPage = 1
      this.getBuiltPro()
    },
    // 处理分页
    handleCurrentChange(val) {
      if (getToken()) {
        this.searchForm.start = (val - 1) * this.searchForm.limit
        this.getBuiltPro()
      } else {
        this.$refs.dialogviporlogin.handleShow('login')
        this.currentPage = 1
      }
    },
    authCheck() {
      const authObj = authenticate('builtPro', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    // 标红搜索关键词
    formatTitle(titleInfo) {
      const strReturn = titleInfo.replace(new RegExp(this.searchForm.keyword, 'g'), `<span class="com-highlight">${this.searchForm.keyword}</span>`)
      return strReturn
    }
  },
  mounted() {
    if (this.province.length === 0) {
      this.$store.dispatch('getProvince')
    }
    if (this.projectType.length === 0) {
      this.$store.dispatch('getProjectType')
    }

    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 115rem;
  margin: auto;
}

// 搜索
.search-info {
  margin: 0 auto;
  width: 700px;
  display: flex;

  .search-senior {
    flex: 1;
    margin-left: 20px;
    button {
      width: 80px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
// 搜索框
.project {
  &-search {
    background: #ffffff;
    &-input {
      width: 660px;
      height: 40px;
      display: flex;
      margin: 0 auto;
    }

    &-field {
      flex: 1;
      border: solid 2px #398ce2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #898a8c;
      /deep/ .el-input__inner {
        border-radius: 0;
        height: 36px;
        line-height: 36px;
      }
    }

    &-btn {
      right: 0;
      width: 56px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;

      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

// 筛选信息
.project {
  &-search-senior {
    background: #ffffff;
    margin: 10px auto;
    padding: 5px 10px;
    &-item {
      display: flex;
      font-size: 14px;
      padding: 18px 0px 10px;
      &:not(:last-child) {
        border-bottom: 1px dashed #e0e0e0;
      }
      &-title {
        width: 100px;
        color: #686868;
        text-align: right;
        padding: 4px 15px 4px 0px;
      }
      &-content {
        flex: 1;
        display: flex;
        .autoH {
          height: auto !important;
        }
        .notice-type-content {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          height: 30px;
          overflow: hidden;
          &-item {
            padding: 4px 7px;
            cursor: pointer;
            margin: 0 2px 10px 0;
            color: #398ce2;
          }
          .active {
            background-color: #398ce2;
            color: #ffffff;
            border-radius: 2px;
          }
        }
        // 更多操作
        .showmore-tools {
          width: 60px;
          padding: 4px 7px;
          cursor: pointer;
          color: #686868;
        }

        .price-range {
          display: flex;
          width: 500px;
          &-item {
            flex: 1;
            &-delive {
              width: 20px;
              line-height: 28px;
              text-align: center;
            }

            &-btn {
              width: 100px;
              margin-left: 5px;
            }
          }
        }

        .publish-time {
          margin-top: -5px;
        }

        .area-content {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          height: 30px;
          overflow: hidden;
          &-item {
            padding: 4px 7px;
            cursor: pointer;
            margin: 0 2px 10px 0;
            color: #398ce2;
          }
          .active {
            background-color: #398ce2;
            color: #ffffff;
            border-radius: 2px;
          }
        }
      }
    }
  }
}

// 标讯列表
.project {
  &-list {
    margin-top: 10px;
    background: #ffffff;

    &-title {
      padding: 10px 15px;
      border-left: 2px solid #398ce2;
      font-size: 16px;
      border-bottom: 1px solid #dddddd;
    }

    &-info {
      margin-top: 10px;
      padding: 10px 15px 0;
      .project-list-info-item {
        margin-bottom: 15px;
        padding-bottom: 15px;
        cursor: pointer;
        border-bottom: 1px solid #ddd;

        .project {
          &-info {
            display: flex;
            font-size: 14px;
          }
          &-title {
            flex: 1;
            overflow: hidden; //超出的文本隐藏
            text-overflow: ellipsis; //溢出用省略号显示
            white-space: nowrap; //溢出不换行
            a {
              cursor: pointer;
              &:hover {
                color: #398ce2;
              }
            }
          }
          &-time {
            width: 160px;
          }

          &-tag-btn {
            margin-top: 10px;
            display: flex;
            .project {
              &-tag {
                flex: 1;
                .el-tag {
                  margin-right: 5px;
                }
              }
              &-btn {
                width: 100px;
                .depth-analysis {
                  background-color: #009c95 !important;
                  border-color: #009c95 !important;
                  color: #fff !important;
                  border: 1px solid #009c95;
                  cursor: pointer;
                  padding: 2px 5px;
                  border-radius: 2x;
                }
              }
            }
          }
        }
      }
    }
  }
}

// 分页
.project-page {
  padding: 10px;
  text-align: right;
}
</style>
